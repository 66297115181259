type Redirect = (route: string) => string | false;

export const loggedInRedirects: Redirect[] = [
  route =>
    ['/login'].includes(route) && !['/404'].includes(route) && '/dashboard',
];

export const loggedOutRedirects: Redirect[] = [
  route =>
    !['/login', '/signup', '/invitation', '/404'].includes(route) && '/login',
];
