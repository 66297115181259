import React, { useEffect, useState } from 'react';
import { VetterDisabledModal } from '@vette/ui-components';
import { useVetterUpdatedSubscription } from '@vette/data-access';

export const DisabledWatcher: React.FC<{
  userId: string;
}> = ({ userId }) => {
  const [showModal, setShowModal] = useState(false);

  const vetterUpdatedSubscriptionResult = useVetterUpdatedSubscription({
    variables: {
      userId,
    },
  });

  const vetterIsDisabled =
    vetterUpdatedSubscriptionResult.data?.vetterUpdated?.disabledAt;

  useEffect(() => {
    if (vetterIsDisabled) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vetterIsDisabled]);

  return (
    <>
      {userId && vetterIsDisabled && (
        <VetterDisabledModal visible={showModal} />
      )}
    </>
  );
};
